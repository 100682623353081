import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import 'bootstrap/dist/css/bootstrap.css';
import { NavLink } from 'react-router-dom';
import './contact.scss';
import Header from '../header';
import Footer from '../footer';
import contact from '../images/contact-us.png';
import shape from '../images/shape_bottom.png';
import msg from '../images/data-msg.png';
import call from '../images/data-call.png';

const Contact = () => {
    const [modal, setModal] = useState(false);
    const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_wpuo9ix', 'template_m9qyr5e', form.current, 'Qq6Y5iaRh_iyI0Zbr')
      .then((result) => {
          console.log(result.text);
            setModal(true)
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
      <div>
          <Header/>
          <div className="container">
          <div className="banner-info">
                  <div className="content">
                  <div className="row">
                      <div className="col-sm-8">
                          <h1>Contact Us </h1>
                          {/* <p>Need more information? We’re here to answer any questions you may have about Wenidi Technologies.</p> */}
                          <p>Reach out to us with any questions or inquiries. We're here to help and look forward to connect with you.</p>
                      </div>
                      <div className="col-sm-4">
                         <img src={contact}/>
                      </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="container">
              <div className="box-data">
                  <div className="inner-box">
                      <div className="row">
                          <div className="col-sm-6">
                              <div className="inner-info">
                                  <h4>Contact Us</h4>
                                  <p>Ask us anything and we'll help you find the answers.</p>
                                  <p>Response time: within 24 hours</p>
                                  <div className="contact-info"><img src={msg}/> info@wenidi.com</div>
                                  <div className="contact-info"><img src={call}/> +91 9870143245, +91 9821297193 (IND)</div>
                                  <div className="contact-info"><img src={call}/> +1 346-657-5594 (USA)</div>
                                  <p className="maginn-data">Corporate Office: </p>
                                  <p className="margin-top">C-1002, Sector 15, Gautam Buddha Nagar, Noida, UP 201301</p>
                              </div>
                          </div>
                          <div className="col-sm-6">
                              <div className="get-in-touch">
                                  <h5>Get in Touch</h5>
                                  <p>Feel free to drop us a line below!</p>
                                  <form ref={form} onSubmit={sendEmail}>
                                  <div className="form-group">
                                    <label for="Name">Name </label>
                                    <input required type="text" className="form-control" name="user_name"></input>
                                  </div>
                                  <div className="form-group">
                                    <label for="email-id">Email </label>
                                    <input required type="email" className="form-control" name="user_email"></input>
                                  </div>
                                  <div className="form-group">
                                    <label for="mobile">Phone Number </label>
                                    <input required type="number" className="form-control" name="user_mobile"></input>
                                  </div>
                                  <div className="form-group">
                                    <label for="message">Message </label>
                                    <textarea required className="form-control" name="message"></textarea>
                                  </div>
                                  <div class="text-center">
                                    <input class="btn btn-primary waves-effect waves-light" type="submit" onReset={form} value="Send Message"/>
                                    </div>
                                  </form>
                                  {modal && 
                                    <div className='thanksYou'>
                                        <div class="wrapper-2">
                                        <h1>Thank you !</h1>
                                        <p>Your submission has been received successfully. Our team is eager to assist you and will be in touch asap.</p>
                                        <NavLink className="nav-link" to="/"> <button class="go-home">
                                        go home
                                        </button>
                                        </NavLink>
                                        </div>
                                    </div>
                                  }
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="shapeData">
          {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3503.8391949236757!2d77.31136331508131!3d28.574591082440673!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce59d528f4991%3A0x11e4ee9124192932!2sWeWork%20Berger%20Delhi%20One!5e0!3m2!1sen!2sin!4v1676603582741!5m2!1sen!2sin"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
           <div className="container">
               <div className='outerMapInfo'>
                  <h3>Our locations</h3>
                  <div className="elementor-widget-container">
                    <p>Our Headquarter is in <b>Noida, U.P. (India)</b></p>
                    {/* <p>Upcoming locations: <b>USA</b>, <b>Mexico</b>, and <b>UK</b></p> */}
				  </div>
                  <div class="images_wrap">
                    {/* <img class="ls-is-cached lazyloaded" src="/images/map-lt-gray.webp" alt="map"/> */}
                    <div className='pins_image_hover'><img title='Noida' decoding="async"  class="ihotspot_hastooltop ls-is-cached lazyloaded" src="/images/direction.png" alt="direction" style={{width:20}}/>
                        <div className='GetInTochInfo'>
                            <h5>Wenidi Technologies transformation through consulting and IT services.</h5>
                            <a href="https://goo.gl/maps/pFK96jHT9cptZd17A" target='_blank'>Berger Delhi One, Sector 16B, C-001/A2, Gautam Buddha Nagar, Noida, UP 201301</a>
                            <div className='getDirections'><a href="https://goo.gl/maps/pFK96jHT9cptZd17A" target='_blank'><b>Get Directios</b></a></div>
                            <p>Monday: 9:00 AM – 6:00 PM</p>

                            <p>Tuesday: 9:00 AM – 6:00 PM</p>

                            <p>Wednesday: 9:00 AM – 6:00 PM</p>

                            <p>Thursday: 9:00 AM – 6:00 PM</p>

                            <p>Friday: 9:00 AM – 6:00 PM</p>

                            <p>Saturday: Closed</p>

                            <p>Sunday: Closed</p>
                        </div>
                    </div>
                    {/* <div className='ukInfo'><img title='UK' decoding="async"  class="ihotspot_hastooltop ls-is-cached lazyloaded" src="/images/directionRed.png" alt="direction" style={{width:20}}/></div>
                    <div className='mexico'><img title='Mexico' decoding="async"  class="ihotspot_hastooltop ls-is-cached lazyloaded" src="/images/directionRed.png" alt="direction" style={{width:20}}/></div>
                    <div className='usa'><img title='USA' decoding="async"  class="ihotspot_hastooltop ls-is-cached lazyloaded" src="/images/directionRed.png" alt="direction" style={{width:20}}/></div> */}
                </div>
               </div>
            </div>
          </div>
          <Footer/>
      </div>
    );
}

export default Contact;